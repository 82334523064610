import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function PayPalButton() {

  this.checkValid = checkValid

  this.data = {
    name: '',
    price: '',
  }

  this.validation = {
    name: false,
    price: false,
  }

  this.validationTranslate = {
    name: '',
    price: '',
  }

  this.validationTxt = {
    name: false,
    price: false,
  }

  /**
   * Getters
   */
  this.getName = () => {
    return this.data.name
  }

  this.getPrice = () => {
    return this.data.price
  }

  /**
   * Setters
   */
  this.setName = (val) => {
    this.data.name = val
  }

  this.setSPrice = (val) => {
    this.data.price = val
  }

}

/**
 * Validations
 */

PayPalButton.prototype.firstValidation = function () {

  let validationItems = {
    name: this.getName(),
    price: this.getPrice(),
  }

  let validationOptions = {
    name: {type: ['empty']},
    price: {type: ['numeric', 'not-zero', 'empty']},
  }

  return (this.checkValid(validationItems, validationOptions))
}

/**
 * Prepare Data
 */

PayPalButton.prototype.prepareSave = function() {

  return {
    "name": this.getName(),
    "price": +this.getPrice(),
  }

}
