
export const payPalButtonMixin = {

  methods: {

    save() {

      console.log(111);
      if(!this.PayPalButton.firstValidation()) return

      let data = this.PayPalButton.prepareSave()

      let saveType = 'createPayPalButtons'

      this.$store.dispatch(saveType, data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')
        console.log(data);

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordCreated')

            this.$router.push(this.$store.getters.GET_PATHS.financeUkrTreasuresLink + '?page=1&count=25&type=paypalListings')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })

    }
  }

}
