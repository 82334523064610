<template>
  <UkrTreasuresPayPalButtonsCreateSection
      :PayPalButton="PayPalButton"
      @save="save"
  />
</template>

<script>
  import UkrTreasuresPayPalButtonsCreateSection from "./UkrTreasuresPayPalButtonsCreateSection/UkrTreasuresPayPalButtonsCreateSection";
  import {PayPalButton} from "../../models/PayPalButton";
  import {payPalButtonMixin} from "../../../../../mixins/payPalButtonMixins/payPalButtonMixin";

  export default {
    name: "UkrTreasuresPayPalButtonsCreate",

    components:{
      UkrTreasuresPayPalButtonsCreateSection,
    },

    mixins: [payPalButtonMixin],

    watch: {
      loadUserAuthorizedData() {
        this.checkAccess()
      },
    },

    mounted() {
      if(this.loadUserAuthorizedData){
        this.checkAccess()
      }
    },

    data() {
      return {
        PayPalButton: new PayPalButton(),
      }
    },

    methods: {
      checkAccess() {
        if (this.$store.getters.getUserProfile.user_setting?.use_paypal_button !== 1 && !this.isAdmin) {
          this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      },
    }

  }
</script>

<style scoped>

</style>
